var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3496e82f289c8cc782db3df3fc449fa52d08849f"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/next";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
    extraErrorDataIntegration,
    captureConsoleIntegration,
} from '@sentry/integrations';
import { NetworkRequestError } from '@frontend/jetlend-core/src/ducks/api';

Sentry.init({
    dsn: 'https://822719ba6eedcd573294b86826bb8b24@sentry.jetlend.ru/11',
    tunnel: '/trace.php',
    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    tracesSampleRate: 1,
    debug: false,

    sampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    integrations: [
        extraErrorDataIntegration({
            depth: 5,
        }),
        captureConsoleIntegration({
            levels: ['error'],
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
        }),
    ] as any,

    beforeBreadcrumb: (breadcrumb, hint) => {
        if (breadcrumb.category === 'fetch' && breadcrumb.message) {
            // Filter out external FETCH requests
            if (/mc\.yandex\.ru/.test(breadcrumb.message)) {
                return null;
            }
        }

        return breadcrumb;
    },

    beforeSend: (error, hint) => {
        const exception = hint.originalException;

        if (exception instanceof NetworkRequestError) {
            const causeError = exception.cause ?? exception;
            if (causeError) {
                const networkErrorMessages = [
                    /Network error/i,
                    /Request aborted/i,
                    /timeout of \d+ms exceeded/i,
                ];

                if (networkErrorMessages.some(regex => regex.test(causeError.message))) {
                    error.fingerprint = ['network-error'];
                } else if (/Request failed with status code (404)/.test(causeError.message)) {
                    error.fingerprint = ['not-found-error'];
                } else if (/Request failed with status code (500)/.test(causeError.message)) {
                    error.fingerprint = ['server-error'];
                } else if (/Request failed with status code (502|503|504)/.test(causeError.message)) {
                    error.fingerprint = ['server-unavailable'];
                }
            }
        }

        return error;
    },
});
